import React from 'react';
import './main.scss';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t } = useTranslation();

    return (
        <div className="contact-form-container">
            <h2>{t("contactus")}</h2>
            <p>{t("information")}</p>
            <form className="contact-form">
                <input type="text" placeholder={t("inputname")} required />
                <div className="contact-form-group">
                    <input type="number" placeholder={t("inputnumber")} required />
                    <input type="email" placeholder={t("inputemail")} required />
                </div>
                <input type="text" placeholder={t("inputsubject")} required />
                <textarea placeholder={t("inputmassage")} rows="4" required></textarea>
                <button type="submit">{t("sendingbutton")}</button>
            </form>
        </div>
    );
};

export default ContactForm;