import React from 'react';
import Slider from 'react-slick';
import { Row, Col } from 'react-bootstrap';
import './main.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';



const CarouselText = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    const { t } = useTranslation();

    const  newsData= [
        {text:`${t("bigtext2")}`, name:`${t("bigtext2name")}`},
        {text:`${t("bigtext")}`,name:`${t("bigtextname")}`},
    ];
    return (
        <div className="bg-body-secondary">
            <div className="container p-5">
                <div className="text-center">
                    <h1>{t("comment")}</h1>
                    <p className="text-muted">{t("klent")}</p>
                </div>
                <Row>
                <Slider {...settings}>
                    {newsData.map((news, index) => (
                        <div key={index} >

                                <Col md={12} className="mt-3">
                                    <div className="card news-card2">
                                        <div className="card-body">
                                            <div className="quota">
                                                <i className="fa-solid fa-quote-left"></i>
                                            </div>
                                            <p className="card-text caru-p">{news.text}</p>
                                            <h5 className="card-title">{news.name}</h5>
                                        </div>
                                    </div>
                                </Col>

                        </div>
                    ))}
                </Slider>
                </Row>
            </div>

        </div>
    );
};

export default CarouselText;