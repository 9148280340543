import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./navbar/navbar";

const App = () => {
    return (
        <div>
            <Navbar/>
        </div>
    );
};
export default App;