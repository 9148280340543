import React from 'react';
import "./main.css"
import Component from "../Component/Component";
import Tour from "../Tour/Tour";
import AboutCompany from "../Company/Company";
import PopularPlace from "../PopularPlace/PopularPlace";
import CarouselText from "../CaruoselText/CaruoselText";
import ContactForm from "../Entering/Entering";
import NewsCarousel from "../CaruoselFooter/CaruoselFooter";
import Footer from "../Footer/Footer";
import img from "./img/logo-white-removebg-preview.png"
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
const Navbar = () => {

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
    };
    const { t } = useTranslation();
    return (
        <div>

            <div id="section1">
                <div className="Alfa">
                    <div className="container Box">
                        <div className="Delta">
                            <p>📞 +998 90 289 23 21 </p>
                            <p className=" martis">📧 hiloljurayev@gmail.com</p>
                        </div>
                        <div>
                            <select onChange={changeLanguage} className="Select">
                                <option  value="uz">Uzb</option>
                                <option  value="en">Eng</option>
                                <option  value="ru">Rus</option>
                            </select>
                        </div>
                    </div>

                    <hr/>

                    <div className="container d-flex align-baseline">


                        <div className="Sadir">
                            <div>
                                <img src={img} width="180" height="180" alt="" className="TourRasm"/>
                            </div>

                            <div className="dropdown DropdownX">
                                <button type="button " className="btn btn XButton border-danger" data-bs-toggle="dropdown">
                                    ❌
                                </button>
                                <ul className="dropdown-menu mt-3 text-white DropdownUl">
                                    <li className="nav-item wer">
                                        <a className="nav-link fw-bold" href="#section1">
                                            {t("home")} <i className="fa-sharp fa-solid fa-house Logo"></i>
                                        </a>
                                    </li>
                                    <hr/>
                                    <li className="nav-item wer">
                                        <a className="nav-link fw-bold" href="#section2">
                                            {t("company")} <i className="fa-solid fa-building Logo"></i>
                                        </a>
                                    </li>
                                    <hr/>
                                    <li className="nav-item wer">
                                        <a className="nav-link fw-bold" href="#section3">
                                            {t("tour")} <i className="fa-solid fa-plane Logo"></i>
                                        </a>
                                    </li>
                                    <hr/>
                                    <hr/>
                                    <li className="nav-item wer">
                                        <a className="nav-link fw-bold" href="#section5">
                                            {t("comment")} <i className="fa-regular fa-comment-dots Logo"></i>
                                        </a>
                                    </li>
                                    <hr/>
                                    <li className="nav-item wer">
                                        <a className="nav-link fw-bold" href="#section6">
                                            {t("contact")} <i className="fa-regular fa-address-card Logo"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <nav className="navbar navbar-expand-sm navbar-dark Nav">
                            <div className="container-fluid">
                                <ul className="navbar-nav ms-3">
                                    <li className="nav-item">
                                        <a className="nav-link fw-bold text-white wer21" href="#section1">
                                            {t("home")} <i className="fa-sharp fa-solid fa-house Logo"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link fw-bold text-white wer21" href="#section2">
                                            {t("company")} <i className="fa-solid fa-building Logo"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link fw-bold text-white wer21" href="#section3">
                                            {t("tour")} <i className="fa-solid fa-plane Logo"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link fw-bold text-white wer21" href="#section5">
                                            {t("comment")} <i className="fa-regular fa-comment-dots Logo"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link fw-bold text-white wer21" href="#section6">
                                            {t("contact")} <i className="fa-regular fa-address-card Logo"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>




                        <button className="Button">{t("button")}</button>


                    </div>
                </div>

                <div id="demo" className="carousel slide" data-bs-ride="carousel">

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="https://mandarin-tour.uz/wp-content/uploads/2022/01/slider2.webp" alt="Los Angeles" className="d-block" width="100%"/>
                        </div>
                        <div className="carousel-item">
                            <img src="https://mandarin-tour.uz/wp-content/uploads/2022/01/7.webp" alt="Chicago" className="d-block" width="100%"/>
                        </div>

                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                        <span className="carousel-control-next-icon"></span>
                    </button>
                </div>
            </div>

            <div>
                <div>
                    <Component/>
                </div>
                <div>
                    <Tour/>
                </div>
                <div id="section3">
                    <PopularPlace/>
                </div>
                <div id="section2">
                    <AboutCompany/>
                </div>
                <div id="section5">
                    <CarouselText/>
                </div>
                <div id="section6">
                    <ContactForm/>
                </div>
                <div>
                    <NewsCarousel/>
                </div>
                <div>
                    <Footer/>
                </div>

            </div>

        </div>
    );
};

export default Navbar;
