import React from 'react';
import Slider from 'react-slick';
import { Row, Col } from 'react-bootstrap';
import './main2.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "./img/photo_2024-08-16_13-38-20.jpg";
import img2 from "./img/photo_2024-08-16_13-38-44.jpg";
import img6 from "./img/photo_2024-08-16_13-37-08.jpg";
import { useTranslation } from 'react-i18next';





const NewsCarousel = () => {
    const { t } = useTranslation();
    const newsData = [
        {
            image: img1,
            title:`${t("istanbul")}`,
            description:`${t("newsistanbul")}`,
            date: '06.04.2022',
            link: '/news/1'
        },
        {
            image: img2,
            description:`${t("newsdubay")}`,
            title:`${t("dubay")}`,
            date: '06.04.2022',
            link: '/news/2'
        },
        {
            image: img6,
            title:`${t("family")}`,
            description:`${t("newsfamily")}`,
            date: '01.04.2022',
            link: '/news/6'
        },

    ];
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="bg-body-secondary">
            <div className="container p-5">
                <div className="text-center">
                    <h1>{t("news")}</h1>
                </div>
                <Row>
                    <Slider {...settings}>
                        {newsData.map((news, index) => (
                            <div className="" key={index} >

                                <Col md={12} className="" >
                                    <div className="card news-card" >
                                        <img src={news.image} className="card-img-top" alt={news.title} />
                                        <div className="card-body">
                                            <h5 className="card-title">{news.title}</h5>
                                            <p className="card-text text2">{news.description}</p>
                                            <div className="caru-footer justify-content-between align-items-center">
                                                <a href={news.link} className="btn btn-primary">{t("batafsil")}</a>
                                                <small className="text-muted d-flex"><span><i className="fa-solid fa-calendar"></i></span>{news.date}</small>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </div>
                        ))}
                    </Slider>
                </Row>
            </div>

        </div>
    );
};

export default NewsCarousel;