import React, { useState } from "react";
import "./main.scss";
import { useTranslation } from 'react-i18next';



function App() {


    const { t } = useTranslation();

    const destinations = [
        { id: 1, name: `${t("evropa")}`, description: "Dunyoning eng kichik qismlaridan biri...", videoId: "uot_VH42ZaY" },
        { id: 2, name: `${t("srilanka")}`,description:"Dunyoning eng kichik qismlaridan biri...1", videoId: "PRfDnjc4shY" },
        { id: 3, name: `${t("sharm")}`,description:"Dunyoning eng kichik qismlaridan biri...2", videoId: "GvHLsNY_bgE" },
        { id: 4, name: `${t("tailand")}`,description:"Dunyoning eng kichik qismlaridan biri...3", videoId: "31nUWUvvq4I" },
        { id: 5, name: `${t("dubay")}`,description:"Dunyoning eng kichik qismlaridan biri...4", videoId: "GeGRBGDsSDw" },
        { id: 6, name: `${t("usa")}`,description:"Dunyoning eng kichik qismlaridan biri...5", videoId: "-DdeuYHzSuI" },
        { id: 7, name: `${t("antaliya")}`,description:"Dunyoning eng kichik qismlaridan biri...6", videoId: "h4l_-_hEmHg" },
        { id: 8, name: `${t("misr")}`,description:"Dunyoning eng kichik qismlaridan biri...7", videoId: "BxKZE1URFBM" },
        { id: 9, name: `${t("turkiya")}`,description:"Dunyoning eng kichik qismlaridan biri...8", videoId: "Wt8uQFgokWs" },
        { id: 10, name: `${t("maldiv")}`,description:"Dunyoning eng kichik qismlaridan biri...9", videoId: "m-vYQgkxGsw" },
    ];
    const [selected, setSelected] = useState(destinations[0]);
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => setModalOpen(!isModalOpen);

    return (
        <div className="container position-relative">
            <div className="container2">
                <div className="menu">
                    <div className="column">
                        {destinations.slice(0, 5).map((item) => (
                            <div
                                key={item.id}
                                className={`mb-1 menu-item ${selected.id === item.id ? "active" : ""}`}
                                onClick={() => setSelected(item)}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                    <div className="column">
                        {destinations.slice(5).map((item) => (
                            <div
                                key={item.id}
                                className={`menu-item ${selected.id === item.id ? "active" : ""}`}
                                onClick={() => setSelected(item)}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="content2">
                    <div className="">
                        <h2 className="content-title">{selected.name}</h2>
                        <p className="content-description">
                            {selected.description || "Tavsif mavjud emas."}
                        </p>
                        <button className="video-button" onClick={toggleModal}>
                            {t("video")}
                        </button>
                    </div>
                </div>

                {isModalOpen && (
                    <div className="modal" onClick={toggleModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <span className="close-button" onClick={toggleModal}>
                                &times;
                            </span>
                            <iframe
                                className="video-player"
                                src={`https://www.youtube.com/embed/${selected.videoId}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default App;
