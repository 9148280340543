import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Tarjima resurslari (tillar va ularning tarjimalari)
const resources = {
    en: {
        translation: {
            home: "Home",
            company:"Company",
            tour:"Travel",
            comment:"Comments",
            contact:"Contacts",
            button:"leave a comment",
            evropa:"Europe",
            usa:"Usa",
            srilanka:"SRI LANKA",
            antaliya:"ANTALYA",
            sharm:"SHARM EL SHEIKH",
            misr:"EGYPT",
            tailand:"THAILAND",
            turkiya:"TURKIYE",
            maldiv:"MALDIVES",
            dubay:"DUBAI",
            video:"WATCH VIDEO",
            ekskursiya:"Excursions",
            name:"We wish you a good holiday",
            days:"Duration 8 days.",
            days1:"Duration 7 days.",
            days2:"Duration 6 days.",
            days3:"Duration 5 days.",
            popular:"Popular places",
            surname:"We offer you only the best",
            more:"More",
            text2:"About the company",
            text:"Recently, the demand for quality organized Recreation has increased significantly among the population of Uzbekistan. In this regard, the number of various tourist offers has increased. In order not to get lost in different species, it is necessary to have up-to-date information about the features of organizing rest in different countries. Our travel agency attracts experienced specialists in organizing trips of any level of complexity for individual and group recreation. We offer a complete set of high-quality travel services offered on favorable terms. We will give you not only confidence in the successful organization of your trip, but also an unforgettable and interesting holiday. You can trust us completely!",
            klent:"Our clients",
            bigtext: "We had a great time with our little ogle (11 months old), thank you very much manager for choosing a great trip and thank you very much for your patience. I have to admit that I am a very nervous customer. Terrible reminder, at first I was worried about visas for a long time and called almost every hour, then I was sitting at the airport waiting to board the plane, I immediately needed to know which plane we were flying on aerobus or Boeing, and if there was an aerobus , which one) (Well, I didn't want to wait another hour to check). Julia never ignored such stupid requests, she immediately admitted everything, I assured her. The celebration was successful. We want to fly there next year. And of course only through you. By the way, if there's something interesting, I'll still get it. Often just",
            bigtextname:"Mubina",
            bigtext2: "Good day,First of all to the travel agency, and I express my gratitude to yours, namely Terekhova Alexandra, for her professionalism, attentive attitude, help in choosing a hotel, competence.My family and I really liked the hotel. It is a family hotel, everything is designed for a holiday with children of different ages. For small children, there is not only a swimming pool, but also a whole park with attractions, for older children and teenagers there are slides, their own entertainment. The territory is large, very green, well-groomed. There are several swimming pools, despite the large number of vacationers, you can always find a free sun lounger. The staff and service were friendly. Room",
            bigtext2name:"Akhmedov Abdulaziz",
            contactus:"Contact us",
            information:"Leave your contact information",
            inputname:"Your full name",
            inputnumber:"Enter your phone number",
            inputemail:"Enter your email",
            inputsubject:"Subject",
            inputmassage:"Your message",
            sendingbutton:"Sending",
            news:"News",
            istanbul:"Istanbul",
            newsistanbul:"News for customers from Hilal Tour! Comfort Life Hotel...",
            newsdubay:"Affordable prices for the most popular tours from Hilal Tour,...",
            family:"What's enough for a family vacation",
            newsfamily:"⏰ Don't think about the last time you spent with your family...",
            batafsil:"More",
            contactinformation:"Contact information",
            locale:"Uzbekistan, Shahrisabz city, Ipak Yuli street, Nomozgoh neighborhood",
            useful:"Useful links",
            ouraddress:"Our address",
        },
    },

    ru: {
        translation: {
            home: "Главная",
            company:"Компания",
            tour:"Путешествия",
            comment:"Комментарии",
            contact:"Контакты",
            button:"оставить заявку",
            evropa:"Европа",
            usa:"США",
            srilanka:"ШРИ-ЛАНКА",
            antaliya:"АНТАЛЬЯ",
            sharm:"ШАРМ-ЭШ-ШЕЙХ",
            misr:"ЕГИПЕТ",
            tailand:"ТАИЛАНД",
            turkiya:"ТУРЦИЯ",
            maldiv:"МАЛЬДИВЫ",
            dubay:"ДУБАЙ",
            video:"СМОТРЕТЬ ВЕДИО",
            ekskursiya:"Экскурсии",
            name:"Желаем вам хорошего отдыха",
            days:"Продолжительность 8 дней.",
            days1:"Продолжительность 7 дней.",
            days2:"Продолжительность 6 дней.",
            days3:"Продолжительность 5 дней.",
            popular:"Популярные места",
            surname:"Мы предлагаем вам только лучшее",
            more:"Более",
            text2:"О компании",
            text:"В последнее время спрос на качественно организованный отдых среди населения Узбекистана значительно возрос. В связи с этим увеличилось количество различных туристических предложений. Чтобы не запутаться в разных видах, необходимо обладать актуальной информацией об особенностях организации отдыха в разных странах. Наше турагентство привлекает опытных специалистов в организации туров любого уровня сложности для индивидуального и группового отдыха. Мы предлагаем полный набор качественных туристических услуг, которые предоставляются на выгодных условиях. Мы дарим вам не только уверенность в успешной организации вашего путешествия, но и незабываемый и приятный отдых. Вы можете нам полностью доверять!",
            klent: "Наши клиенты",
            bigtext:"Мы прекрасно провели время с нашим маленьким глазком (11 месяцев), спасибо большое менеджеру за выбор отличной поездки и огромное спасибо за терпение. Должен признаться, что я очень нервный клиент. Страшное напоминание, сначала я долго переживал по поводу виз и звонил чуть ли не каждый час, потом сидел в аэропорту в ожидании посадки на самолет, мне сразу нужно было узнать, на каком самолете мы летим, на аэробусе или Боинге, и если был аэробус, какой именно) (Ну не хотелось ждать еще час, чтобы проверить). Юлия никогда не игнорировала такие глупые просьбы, она сразу во всем созналась, я ее заверил. Торжество прошло успешно. Хотим полететь туда в следующем году. И конечно только через вас. Кстати, если будет что-то интересное, я все равно получу. Часто просто",
            bigtextname:"Мубина",
            bigtext2:"Добрый день,B первую очередь турагентству, а выражаю благодарность Вашему именно Терехиной Александре за профессионализм, внимательное отношение, помощь в выборе отеля, компетентность.Мне и моей семье очень понравился отель. Он именно семейный, все предназначено для отдыха с детьми притом разного возраста. Для маленьких детей не только бассейн, но и целый парк с аттракционами, для детей постарше и подростков свои горки, свои развлечения. Территория большая, очень зеленая, ухоженная Бассейнов несколько, несмотря на большое количество отдыхающих, всегда можно найти свободный лежак. Персонал и обслуживание были доброжелательны. Номер",
            bigtext2name:"Ахмедов Абдулазиз",
            contactus:"Связаться с нами",
            information:"Оставьте свою контактную информацию",
            inputname:"Ваше полное имя",
            inputnumber:"Введите свой номер телефона",
            inputemail:"Ваше полное имя",
            inputsubject:"Предмет",
            inputmassage:"Ваше сообщение",
            sendingbutton:"Отправка",
            news:"Новости",
            istanbul:"Стамбул",
            newsistanbul: "Новости для клиентов Хилал Тур! Комфорт Лайф Отель...",
            newsdubay:"Доступные цены на самые популярные туры от Hilal Tour,...",
            family:"Чего достаточно для семейного отдыха",
            newsfamily:"⏰ Не думайте о том, когда вы в последний раз проводили время с семьей...",
            batafsil:"Более",
            contactinformation:"Контактная информация",
            locale:"Узбекистан, город Шахрисабз, улица Ипак Йули, микрорайон Номозгох",
            useful: "Полезные ссылки",
            ouraddress:"Наш адрес",
        },
    },
    uz: {
        translation: {
            home: "Bosh sahifa",
            company:"kompaniya",
            tour:"Sayohatlar",
            comment:"Sharhlar",
            contact:"Aloqalar",
            button:"izohni qoldiring",
            evropa:"Evropa",
            usa:"AQSh",
            srilanka:"Shri lanka",
            antaliya:"Antaliya",
            sharm:"SHARM ESH-SHEYX",
            misr:"MISR",
            tailand:"TAILAND",
            turkiya:"TURKIYA",
            maldiv:"MALDIV",
            dubay:"DUBAY",
            video:"VIDEONI KO'RING",
            ekskursiya:"Ekskursiyalar",
            name:"Sizga yaxshi dam olishni tilaymiz",
            days:"Davomiyligi 8 kun",
            days1:"Davomiyligi 7 kun",
            days2:"Davomiyligi 6 kun",
            days3:"Davomiyligi 5 kun",
            popular:"Mashxur joylar",
            more:"Kop'roq",
            surname:"Biz sizga faqat eng yaxshisini taklif qilamiz",
            text2:"Kompaniya haqida",
            text:"So'nggi paytlarda O'zbekiston aholisi orasida sifatli tashkil etilgan dam olishga bo'lgan talab sezilarli darajada oshdi. Shu munosabat bilan turli turistik takliflar soni ortdi. Turli xil turlarda adashib qolmaslik uchun turli mamlakatlarda dam olishni tashkil qilish xususiyatlari haqida dolzarb ma'lumotlarga ega bo'lish kerak. Bizning sayyohlik agentligimiz individual va guruhli dam olish uchun har qanday murakkablik darajasidagi sayohatlarni tashkil qilishda tajribali mutaxassislarni jalb qiladi. Biz qulay shartlarda taqdim etiladigan yuqori sifatli sayohat xizmatlarining to'liq to'plamini taklif etamiz. Biz sizga nafaqat sayohatingizning muvaffaqiyatli tashkil etilishiga ishonch, balki unutilmas va maroqli dam olishni ham beramiz. Bizga to'liq ishonishingiz mumkin!",
            klent:"Bizning mijozlarimiz",
            bigtext:"Biz kichkina oglimiz (11 oylik) bilan dam oldik, menejer ajoyib sayohatni tanlaganingiz uchun katta rahmat va sabr-toqatingiz uchun sizga katta rahmat. Tan olishim kerak men juda asabiy mijozman. Dahshatli ogohlantiruvchi dastlab men uzoq vaqt vizalar haqida qayg'urardim va deyarli har soatda qo'ngiroq qilardim, keyin aeroportda o'tirib, samolyotga chiqishni kutardim, men zudlik bilan qaysi samolyotda aerobus yoki Boingda uchayotganimizni bilishim kerak edi., va agar aerobus bo'lsa, qaysi biril) (Xo'sh, tekshirish uchun yana bir soat kutishni xohlamadim). Yuliya hech qachon bunday ahmoqona so'rovlarni etiborsiz qoldirmadi, u darhol hamma narsani tan oldi, menm ishontirdi. Bayram muvaffaqiyatli o'tdi. Kelgusi yil u yerga uchmoqchimiz. Va, albatta faqat siz orqali Aytgancha, agar qiziq narsa bolsa, men uni hali ham olynayman. Ko'pincha shunchak",
            bigtextname:"Mubina",
            bigtext2:"Hayrli kun,Avvalo, sayyohlik agentligiga va men sizga, Terexina Aleksandra, uning professionalligi, ehtiyotkorlik bilan munosabati, mehmonxona tanlashda yordami va malakasi uchun o'z minnatdorchiligimni bildiraman.Mening oilam va menga mehmonxona juda yoqdi. Bu oila uchun qulay joy, hamma narsa turli yoshdagi bolalari bo'lgan oilalar uchun mo'ljallangan. Kichkina bolalar uchun nafaqat suzish havzasi, balki butun istirohat bog'i, kattaroq bolalar va o'smirlar uchun o'z slaydlari va o'yin-kulgilari mavjud. Hudud katta, juda yashil, yaxshi ishlangan, bir nechta suzish havzalari bor, dam oluvchilarning ko'pligiga qaramay, siz har doim bepul quyosh to'shagini topishingiz mumkin. Xodimlar va xizmat samimiy edi. Raqam",
            bigtext2name:"Ahmedov Abdulaziz",
            contactus:"Biz bilan bog'laning",
            information:"Kontak ma'lumotlaringizni qoldiring",
            inputname:"To'liq ismingiz",
            inputnumber:"Telefon raqamingizni kiriting",
            inputemail:"Emailingizni kiriting",
            inputsubject:"Mavzu",
            inputmassage:"Sizning xabaringiz",
            sendingbutton:"Yuborish",
            news:"Yangiliklar",
            istanbul:"Istanbul",
            newsistanbul:"Hilol Tour'dan mijozlar uchun yangilik! Comfort Life Hotel...",
            newsdubay:"Hilol Tour'dan eng ommabop sayohatlar uchun hamyonbop narxlar,...",
            family:"Oilaviy dam olishda nima yetsin",
            newsfamily:"⏰ Oxirgi marotaba oilangiz bilan qancha vaqt o'tkazayotganingizni hech o'ylab...,",
            batafsil:"Batafsil",
            contactinformation:"Aloqa ma'lumotlari",
            locale:"Uzbekiston,Shahrisabz shahar,Ipak yuli kuchasi,Nomozgoh mahhallasi",
            useful:"Foydali havolalar",
            ouraddress:"Bizning manzil",
        },
    },
};

i18n
    .use(LanguageDetector) // Brauzer tilini avtomatik aniqlash uchun
    .use(initReactI18next) // React bilan bog‘lash uchun
    .init({
        resources,
        fallbackLng: 'uz', // Agar til topilmasa, qaysi til ishlatilishi
        interpolation: {
            escapeValue: false, // Xavfsizlik sozlamalari
        },
    });

export default i18n;