import React from 'react';
import './main.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className="bg-primary-subtle">
            <div className="container ">
                <footer className="footer">
                    <div className="footer-container">
                        <div className="footer-section">
                            <h3>{t("contactinformation")}</h3>
                            <ul className="sectionUl">
                                <li><i className="fa uz fa-map-marker"></i><span className="uzer">{t("locale")}</span></li>
                                <li><i className="fa uz fa-phone"></i> <a href="tel:+99890 289-23-21">+998902892321</a></li>
                                <li><i className="fa uz fa-envelope"></i> hiloljurayev@gmail.com</li>
                            </ul>
                        </div>

                        <div className="footer-section">
                            <h3>{t("useful")}</h3>
                            <ul>
                                <li><i className="fa fa-home"></i> {t("home")}</li>
                                <li><i className="fa fa-info-circle"></i>{t("company")}</li>
                                <li><i className="fa fa-plane"></i> {t("tour")}</li>
                                <li><i className="fa fa-comments"></i> {t("comment")}</li>
                                <li><i className="fa fa-address-card"></i> {t("contact")}</li>
                            </ul>
                        </div>

                        <div className="footer-section">
                            <h3>{t("ouraddress")}</h3>
                            <div className="map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509413!2d144.95373531531847!3d-37.81720997975181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d29c129b4091!2sVictoria%20State%20Library!5e0!3m2!1sen!2sau!4v1580143840440!5m2!1sen!2sau"
                                    title="Google Map"
                                    allowFullScreen
                                    loading="lazy"
                                ></iframe>
                            </div>
                        </div>
                    </div>

                </footer>
            </div>
        </div>
    );
};

export default Footer;