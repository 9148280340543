import React from 'react';
import './main.scss';
import Img from "./img/company.jpg"
import { useTranslation } from 'react-i18next';

const AboutCompany = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-body-secondary">
            <div className="container ">
                <div className="about-company-container">
                    <div className="about-company-text">
                        <h2>{t("text2")}</h2>
                        <p className="texter">
                            {t("text")}
                        </p>
                    </div>
                    <div className="about-company-image">
                        <img src={Img} alt="About Company" />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AboutCompany;