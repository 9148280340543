import React from 'react';
import "./main.scss"
import img1 from "./img/img1.webp";
import img2 from "./img/img2.webp";
import img3 from "./img/img3.webp";
import img4 from "./img/img4.webp";
import img5 from "./img/img5.webp";
import img6 from "./img/img6.webp";
import img7 from "./img/img7.webp";
import img8 from "./img/img8.webp";
import { useTranslation } from 'react-i18next';

const PopularPlace = () => {
    const { t } = useTranslation();
    const data=[
        {img:img1,name:`${t("usa")}`},
        {img:img2,name:`${t("srilanka")}`},
        {img:img3,name:`${t("antaliya")}`},
        {img:img4,name:`${t("sharm")}`},
        {img:img5,name:`${t("misr")}`},
        {img:img6,name:`${t("tailand")}`},
        {img:img7,name:`${t("turkiya")}`},
        {img:img8,name:`${t("dubay")}`},
    ];
    return (
        <div className="container">
            <div className="place mt-5">
                <h1 className="">{t("popular")}</h1>
                <p className="text-muted fs-6">{t("surname")}</p>
            </div>
            <div className="row">
                {data.map(item=>(
                    <div key={item.name} className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-4 my-2 overflow-hidden position-relative box">
                        <div className="box w-100 h-100 overflow-hidden">
                            <img src={item.img} alt="" className="w-100 h-100 img"/>
                            <h2 className="position-absolute text">{item.name}</h2>
                            <button className="position-absolute btn btn-danger more">{t("more")}</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default PopularPlace;