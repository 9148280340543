import React from 'react';
import "./main.scss"
import { useTranslation } from 'react-i18next';

const Tour = () => {
    const { t } = useTranslation();

    const data = [
        {
            img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/8.webp",
            name:  `${t("usa")}`,
            surname: "",
            cost: "$2000",

        },
        {
            img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/9.webp",
            name:  `${t("srilanka")}`,
            surname: `${t("days1")}`,
            cost: "$1100"
        },
        {
            img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/4.webp",
            name:  `${t("antaliya")}`,
            surname:  `${t("days")}`,
            cost: "$780"
        },
        {
            img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/6.webp",
            name: `${t("sharm")}`,
            surname: `${t("days3")}`,
            cost: "$595"
        },
        {img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/5.webp", name: `${t("misr")}`,},
        {img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/3.webp", name: `${t("tailand")}`},
        {img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/7.webp", name: `${t("turkiya")}`},
        {
            img: "https://mandarin-tour.uz/wp-content/uploads/2022/01/1.webp",
            name: `${t("dubay")}`,
            surname:  `${t("days2")}`,
            cost: "$420"
        },
    ];
    return (
        <div>
            <div className="container p-5 mt-5">
                <div className="text-center">
                    <h1>{t("ekskursiya")}</h1>
                    <p className="text-muted">{t("name")}</p>
                </div>
                <div className="row">

                    {
                        data.map(item => (
                            <div key={item.name} className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="my-3 tour">
                                    <img src={item.img} alt="" className="w-100 "/>
                                    <div className="position-absoluten content">
                                        <h5 className="fw-bold color">{item.name}</h5>
                                        <p>{item.surname}</p>
                                        <hr className="m-0"/>
                                        <div className="d-flex justify-content-between">
                                            <h5>{item.cost}</h5>
                                            <div className="star">
                                                <ul>
                                                    <li>{item.star}</li>
                                                    <li>{item.star}</li>
                                                    <li>{item.star}</li>
                                                    <li>{item.star}</li>
                                                    <li>{item.star}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    );
};

export default Tour;